import React, { useEffect, useState, useContext } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client'
import { Switch, Route, useLocation} from 'react-router-dom';
import styles from './app.module.scss';
import Header from '../../components/Header/Header';
import Navigation from '../../components/Navigation/Navigation';
import Home from '../Home/Home';
import HomeContextProvider from '../Home/HomeContext';
import Search from '../Search/Search';
import Recent from '../Recent/Recent';
import Favorites from '../Favorites/Favorites';
import Viewer from '../Viewer/PdfViewer';
import ViewerContextProvider from '../Viewer/ViewerContext';
import LightViewer from '../LightViewer/LightViewer';
import HtmlViewer from '../HtmlViewer/HtmlViewer';
import AdminPanel from '../AdminPanel/AdminPanel';
import ModalContextProvider from '../../components/Modal/ModalContext';
import { GET_EMP_PROFILE, GET_EMP_PREFS } from '../../operations/queries/preferences';
import { mutations } from '../../operations/mutations';
import { ReactNotifications, store } from 'react-notifications-component';
import Notification from '../../components/Notification/Notification';
import 'react-notifications-component/dist/theme.css'
import Previewmanual from '../PreviewManual/Previewmanual';
import PreviewContextProvider from '../PreviewManual/PreviewManualContext';
import { UserContext } from './UserContext.js';
import HelpVideo from '../HelpVideo/HelpVideo';

const App = () => {
	const { userId } = useContext(UserContext);

	const profileinfo = useQuery(GET_EMP_PROFILE, {
		variables: {
		  userId: userId
		}
	  });

    const [getPrefs, { loading, error, data }] = useLazyQuery(GET_EMP_PREFS);
	const { setFavorites, setRecent } = mutations;
	const [isAdmin, setIsAdmin] = useState(false);
	const [reviewCount, setReviewCount] = useState(0);
	useEffect(() => {
		const favmanuals = data && data.userPreferences ? data.userPreferences.userFavouriteManuals : [];
		const recmanuals = data && data.userPreferences ? data.userPreferences.recentlyViewedManuals : [];
		if (favmanuals.length > 0) setFavorites([...new Set(favmanuals.map(f => { return { manualCode: f.manualCode } }))]);
		if (recmanuals.length > 0) setRecent([...new Set(recmanuals.map(r => { return { manualCode: r.manualCode }}))])
	}, [data])
	useEffect(() => {
        if (profileinfo && profileinfo.data && profileinfo.data.profileQuery) {
            getPrefs({ variables: { employeeId: userId || 'V726082', totalRecent: 7 } });
			setIsAdmin(profileinfo.data.profileQuery.profile.isAdmin);
			setReviewCount(profileinfo.data.profileQuery.profile.reviewCount);
        }
	}, [profileinfo])
	useEffect(() => {
        if (error) {
            store.addNotification({
				container: 'top-right',
				animationIn: ["animate__animated", "animate__fadeIn"],
				  animationOut: ["animate__animated", "animate__fadeOut"],
				  width: 500,
				dismiss: {
					duration: 400,
					onScreen: true
				},
				content: (
					<Notification title={'Favorites and Recent Manuals failed to load.'} message={'Currently we are unable to retrieve your manuals please retry or email UDocs-App-Support@united.com.'} type={'failure'}/>
				),
				slidingExit: {
					duration: 800,
					timingFunction: 'ease-out',
					delay: 0
				},
				touchSlidingExit: {
					swipe: {
					  duration: 400,
					  timingFunction: 'ease-out',
					  delay: 0,
					},
					fade: {
					  duration: 400,
					  timingFunction: 'ease-out',
					  delay: 0
					}
				}
			});
        }
    }, [error]);

	const location = useLocation();
	return (
		<div className='app-container'>
			<ReactNotifications />
			{location.pathname !== '/pdfviewer' && location.pathname !== '/htmlviewer' ? <ModalContextProvider><Header /></ModalContextProvider> : null}
			<div className={styles['app-body']}>

				{location.pathname !== '/pdfviewer' && location.pathname !== '/htmlviewer' ? <div><ModalContextProvider><Navigation adminlogin={isAdmin} reviewCount={reviewCount}/></ModalContextProvider></div> : null}
				<ViewerContextProvider>
					<HomeContextProvider>
						<PreviewContextProvider>
							<div className={styles.page}>
								<Switch>
									<Route exact path='/'>
										<Home />
									</Route>
									<Route path='/search' render={(props) => <Search {...props} key={Date.now()}/>}>
									</Route>
									<Route path='/recent'>
										<Recent />
									</Route>
									<Route path='/favorites'>
										<Favorites />
									</Route>
									<Route path='/viewer'>
										<Viewer />
									</Route>
									<Route path='/pdfviewer'>
										<LightViewer />
									</Route>
									<Route path='/h-viewer'>
										<HtmlViewer />
									</Route>
									<Route path='/AdminPanel'>
										<AdminPanel />
									</Route>
									<Route path='/PreviewManual'>
										<Previewmanual />
									</Route>
									<Route path='/htmlviewer'>
										<HtmlViewer />
									</Route>
									<Route path='/helpVideo'>
                                        <HelpVideo />
                                    </Route>
								</Switch>
							</div>
						</PreviewContextProvider>
					</HomeContextProvider>
				</ViewerContextProvider>
			</div>
		</div>
	)
}

export default App;
