import { InMemoryCache, makeVar } from "@apollo/client";
import moment from 'moment';

export const cache = new InMemoryCache ({
    typePolicies: {
        Query: {
            fields: {
                search: {
                    read() {
                        return currentSearchVar();
                    }
                },
                recent: {
                    read() {
                        return currentRecentVar();
                    }
                },
                favorites: {
                    read() {
                        return currentFavoriteVar();
                    }
                },
                pins: {
                    read() {
                        return currentPinsVar();
                    }
                },
                localmanuals: {
                    read() {
                        return manualsVar();
                    }
                }, 
                manual: {
                    read() {
                        return currentManualVar();
                    }
                },
                tablecontents: {
                    read() {
                        return tablecontentsVar();
                    }
                },
                reviewer: {
                    read() {
                        return currentReviewerVar();
                    }
                },
                activity: {
                    read() {
                        return currentActivityVar();
                    }
                }
            }
        }
    }
});

/**
 * Set initial values when we create cache variables
 */
const tablecontentsInitialValues = {}
const manualsInitialValues = [];
const initialManual = null;
const initialSearch = null;
const initialRecent = [];
const initialFavorite = [];
const initialPins = [];
const initialReviewer = [];
const initialActivity = [];

export const tablecontentsVar = makeVar(
    tablecontentsInitialValues
);
export const manualsVar = makeVar(
    manualsInitialValues
);
export const currentManualVar = makeVar(
    initialManual
);
export const currentSearchVar = makeVar(
    initialSearch
);
export const currentRecentVar = makeVar(
    initialRecent
);
export const currentFavoriteVar = makeVar(
    initialFavorite
);
export const currentPinsVar = makeVar(
    initialPins
);
export const currentReviewerVar = makeVar(
    initialReviewer
);
export const currentActivityVar = makeVar(
    initialActivity
);