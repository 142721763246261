
const intialState={
    manualName:"",
    fleetType:"",
    revNumber:"",
    revDate:""
}
export const reducer = (state=intialState, action) => {
  switch (action.type) {
    case "MANUAL_NAME":
      return{...state, manualName:action.payload};
      case "FlEET_TYPE":
        return {...state,fleetType:action.payload}
      case "REV_NUMBER":
        return {...state,revNumber:action.payload}
      case "REV_DATE":
        return{...state,revDate:action.payload}

    default:
      return state;
  }
};


 
 