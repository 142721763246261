import React, {useState, useEffect, useContext} from 'react'
// import helpVideoDesktop from '/videos/help_udocs.mp4';
// import helpVideoMobile from '/videos/help_mobile_udocs.mp4';
import { useQuery } from '@apollo/client';
import { GET_EMP_PROFILE } from '../../operations/queries/preferences';
import { UserContext } from "../../containers/App/UserContext.js";
 
const HelpVideo = () => {
      const { userId } = useContext(UserContext);
      const profileinfo = useQuery(GET_EMP_PROFILE, {
            variables: {
              userId: userId
            }
          });
 
    const [isMobile, setIsMobile] = useState(false);
    const [isProfileDataAvailable, setIsProfileDataAvailable] = useState(false);
 
    useEffect(() => {
        if (profileinfo?.data && profileinfo?.data?.profileQuery) {
            setIsProfileDataAvailable(profileinfo?.data?.profileQuery?.profile?true:false);
        }
    }, [profileinfo])
 
    useEffect(()=>{
        const checkIfMobile = () => {
            setIsMobile(window.innerWidth<=768);
        }
        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);
        return ()=> window.removeEventListener('resize', checkIfMobile)
    },[])
 
    return <>
    { isProfileDataAvailable?
        <video width="800" controls>
            <source src={isMobile?"/videos/help_mobile_udocs.mp4":"/videos/help_udocs.mp4"}/>
        </video>:<div>You're not authorized to see this content</div>
        }
    </>
}
 
export default HelpVideo;