import React from 'react';
import { Link } from 'react-router-dom';

import styles from '../modal.module.scss';

const ModalHeader = (props) => (
    <div className={styles['modal-header']}>
        <div className={styles['modal-title']}>{props.title}</div>
        <div >
            {
                props.title==='UDocs Help' ? 
                    <div className={styles['modal-link']}><Link to='/helpVideo' target="_blank">UDocs Help Video</Link></div>
                : null}
            <div className={styles['modal-close']} onClick={() => props.closeModal()}> </div>
        </div>
    </div>
)

export default ModalHeader;