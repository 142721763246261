import React, {useState, useEffect, useContext,useReducer} from 'react';
import { reducer } from './ManualDescriptionReducer.js';
import { useQuery, useLazyQuery} from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import Icon from '../../components/Icon/Icon';
import { GET_DOCUMENT_V2} from '../../operations/queries/documents';
import { GET_EMP_PROFILE } from '../../operations/queries/preferences';
import { GET_EMP_MANUALS } from '../../operations/queries/manuals';
import { mutations } from '../../operations/mutations';
import { ViewerContext } from '../Viewer/ViewerContext';
import styles from './htmlviewer.module.scss';
import { UserContext } from '../App/UserContext';
import ShadowDOM from "../../components/ShadowDOM";
import config from '../../config/development.js';



import moment from 'moment';


const HtmlViewer = () => {
    const { userId } = useContext(UserContext);
    const params = new URLSearchParams(useLocation().search);
    const manualCode = params.get('manualCode'); const fileName = params.get('fileName'); 
    const title=params.get("title");
    const crossManual = params.get('isCrossManual');
    const versionNumber = params.get('version');
    const isPreview = params.get('isPreview');
    const ssi=params.get('isSsi');
    const [htmlBase64, setHtmlBase64]=useState();
    const [revDetails, setRevDetails] = useState();
    const [complianceTag, setComplianceTag]=useState(true);
    const history = useHistory();
    const location = useLocation();
    const [hash, setHash] = useState(location.hash);
    console.log('location', location);

    const profileinfo = useQuery(GET_EMP_PROFILE, {
      variables: {
        userId: userId
      }
      });
    const [getHtmlContent, {loading, error, data}] = useLazyQuery(GET_DOCUMENT_V2)
    const [getEmpManuals, { data: employeeManuals}] = useLazyQuery(GET_EMP_MANUALS);
    const { selectManual } = mutations;
    const { isHtml,setHtml} = useContext(ViewerContext);
     const[manualDescription,dispatch]=useReducer(reducer,"")
  

    useEffect(() => {
      if(title )
      {
        document.title=title;
      }
    }, [])
    useEffect(() => {
      if( profileinfo.data )
      {
        getEmpManuals({ variables: { employeeId: userId || 'V726082' } });
      }
  }, [profileinfo])

  useEffect(()=>{
    let currentLocation=hash 
    let containsHashValue=currentLocation.includes("#");
    if(containsHashValue){
      const hashValue=`${currentLocation.substring(currentLocation.indexOf("#")+1 )}`
    const currentId= hashValue.substring( 0, hashValue.indexOf( "&" ) )
      let shadowElement=document.getElementById("udocs-html-content-shadowroot")
     const content= shadowElement.shadowRoot.getElementById(currentId)
    if(content){
      content.scrollIntoView({ behavior: "smooth" });
    }
  }   
  },[htmlBase64])
  

 
  useEffect(() => {
    console.log('empmanual', employeeManuals);
    if(!fileName&& employeeManuals)
    {
        const foundcategory = employeeManuals.manuals.getManualsForEmployee.find(m => m.fleetManuals.findIndex(fm => fm.manualCode === manualCode) !== -1 ||
                                                                        m.vendorManuals.findIndex(vm => vm.manualCode === manualCode) !== -1 ||
                                                                        m.engineManuals.findIndex(em => em.manualCode === manualCode) !== -1);

        const foundmanual = foundcategory && foundcategory.categoryName === 'Fleet' ? foundcategory.fleetManuals.find(fm => fm.manualCode === manualCode) :
                                foundcategory && foundcategory.categoryName === 'Vendor' ? foundcategory.vendorManuals.find(vm => vm.manualCode === manualCode) :
                                foundcategory && foundcategory.categoryName === 'Engine' ? foundcategory.engineManuals.find(em => em.manualCode === manualCode) : [];

        const newrecent = { categoryName: foundcategory.categoryName, manualName: foundcategory.manualName, manualCode: manualCode, hasAlerts: foundmanual.hasAlerts, revDate: foundmanual.revDate, revNumber: foundmanual.revision ? foundmanual.revision : '',
                                uRL: foundmanual.uRL,typeInfo: foundmanual.fleetType || foundmanual.vendorName || foundmanual.engineType || null,
                                fleetType: foundmanual.fleetType || null, vendorName: foundmanual.vendorName || null, engineType: foundmanual.engineType || null  };

        //const newrecent = { categoryName: 'fleet', manualName: 'GMM', manualCode: manualCode, revDate: '06/04/2021', revNumber: '',
        //                       typeInfo:  null,
        //                       fleetType:  null, vendorName:  null, engineType:  null  };
        //SelectedDocument(null);
        selectManual(newrecent);
        //console.log('newrecent', newrecent, foundmanual);
        setHtml(foundcategory.isHtml);
        
        history.push('/viewer');
    }
    else if(employeeManuals){
      const foundcategory = employeeManuals.manuals.getManualsForEmployee.find(m => m.fleetManuals.findIndex(fm => fm.manualCode === manualCode) !== -1 ||
      m.vendorManuals.findIndex(vm => vm.manualCode === manualCode) !== -1 ||
      m.engineManuals.findIndex(em => em.manualCode === manualCode) !== -1);

const foundmanual = foundcategory && foundcategory.categoryName === 'Fleet' ? foundcategory.fleetManuals.find(fm => fm.manualCode === manualCode) :
foundcategory && foundcategory.categoryName === 'Vendor' ? foundcategory.vendorManuals.find(vm => vm.manualCode === manualCode) :
foundcategory && foundcategory.categoryName === 'Engine' ? foundcategory.engineManuals.find(em => em.manualCode === manualCode) : [];

const newrecent = { categoryName: foundcategory.categoryName, manualName: foundcategory.manualName, manualCode: manualCode, hasAlerts: foundmanual.hasAlerts, revDate: foundmanual.revDate, revNumber: foundmanual.revision ? foundmanual.revision : '',
uRL: foundmanual.uRL,typeInfo: foundmanual.fleetType || foundmanual.vendorName || foundmanual.engineType || null,
fleetType: foundmanual.fleetType || null, vendorName: foundmanual.vendorName || null, engineType: foundmanual.engineType || null  };


setHtml(foundcategory.isHtml);
dispatch({type:'MANUAL_NAME',payload:newrecent.manualName})
dispatch({type:'FlEET_TYPE',payload:newrecent.fleetType})
dispatch({type:'REV_NUMBER',payload:newrecent.revNumber})
dispatch({type:'REV_DATE',payload:newrecent.revDate})
console.log(newrecent.fleetType)

    }
    else{
     return null
    }

}, [employeeManuals])

  const handlePrintClick=()=>{
    var pwa = window.open(htmlBase64, '', '');
    var start=htmlBase64.indexOf('<title>');
    var end= htmlBase64.indexOf('</title>')
    if(ssi==="true")
      pwa.document.write(

        '<table>' +
        `<thead style="height: 120px;" ><tr style=""><td><div style='display:flex'><p style="font-size:10px ">${moment().format(
          "MM/DD/YYYY hh:mm A")}
          </p> <p style="font-size:10px;margin-left:250px;position: relative;"> ${manualDescription.manualName}  </p>  </div>
           </td>
           </tr>
           <tr style="padding:"0px"><td><p style="font-size:10px;margin-left:345px;position: fixed;top: 40px">Uncontrolled, One Time Use Only | rev#: ${manualDescription.revNumber} | rev date: ${moment(manualDescription.revDate).format(
            "MM/DD/YYYY")}</p></td></tr>
           <tr><td><div style="font-size:16px;text-align:center; color:red!important; font-weight:bold;print-color-adjust: exact !important;">SENSITIVE SECURITY INFORMATION</div></td></tr>
           </thead>` +
        "<td>" +
        htmlBase64.substring(0, start) +
        htmlBase64.substring(end) +
        "</td> " +
        `<tfoot>
        <tr>
        <td style="color:red">SSI WARNING: THIS RECORD CONTAINS SENSITIVE SECURITY INFORMATION THAT IS CONTROLLED UNDER 49 C.F.R. PARTS 15 AND 1520. NO PART OF THIS RECORD MAY BE DISCLOSED TO PERSONS WITHOUT A "NEED TO KNOW", AS DEFINED IN 49 C.F.R. PARTS 15 AND 1520, EXCEPT WITH THE WRITTEN PERMISSION OF THE ADMINISTRATOR OF THE TRANSPORTATION SECURITY ADMINISTRATION OR THE SECRETARY OF HOMELAND SECURITY. UNAUTHORIZED RELEASE MAY RESULT IN CIVIL PENALTY OR OTHER ACTION. FOR U.S. GOVERNMENT AGENCIES, PUBLIC DISCLOSURE IS GOVERNED BY 5 U.S.C. 552 AND 49 C.F.R. PARTS 15 AND 1520.1.</td>
        </tr>
      </tfoot>`+
        "</table>"
    )
    else{
      pwa.document.write(
        '<table>' +
                `<thead style="height: 80px;" ><tr style=""><td><div style='display:flex'><p style="font-size:10px ">${moment().format(
                  "MM/DD/YYYY hh:mm A")}
                  </p> <p style="font-size:10px;margin-left:250px;position: relative;"> ${manualDescription.manualName}  </p>  </div>
                   </td>
                   </tr>
                   <tr style="padding:"0px""><td><p style="font-size:10px;margin-left:345px;position: fixed;top: 40px">Uncontrolled, One Time Use Only | rev#: ${manualDescription.revNumber} | rev date: ${moment(manualDescription.revDate).format(
                    "MM/DD/YYYY")}</p></td></tr>
                   </thead>` +
                "<td>" +
                htmlBase64.substring(0, start) +
                htmlBase64.substring(end) +
                "</td> " +
                "</table>"
      );
    }
   pwa.print()
   pwa.close();
}




    useEffect(() => {
      if(profileinfo.data) {
        getHtmlContent({variables: { manualCode: manualCode, fileName: fileName, isHtml: true, isPreview: isPreview ? isPreview: false, versionNumber: crossManual ? (crossManual.trim().toLowerCase()==='true' ? null : versionNumber) : null, includeRevDetails: true, includeComplianceInformation: true, employeeId:  userId }});
      }
    }, [profileinfo])

    useEffect(() => {
      if (data && data.contentStore && data.contentStore.viewFileV2)
        {
            setRevDetails(data.contentStore.viewFileV2.revDetails)
            fetch(config.REACT_APP_DOC_PRESIGNEDURL + manualCode+"/"+fileName+ "/"+ !data.contentStore.viewFileV2.isPreview)
            .then(res => { return res.json()})
                    .then((res) => {
                        fetch(res.s3PreSignedUrl)
                        .then(response => response.text())
                        .then(htmlContent => {
                          let processedHtml = htmlContent
                            .replace(/\b/g, '')    // Remove "\b"
                            .replace(/\n/g, '')    // Remove newlines
                            .replace(/\r/g, '')    // Remove carriage returns
                            .replace('<!DOCTYPE html  SYSTEM "about:legacy-compat">', '')
                            .replace('data:image/jpg; base64, ', 'data:image/jpeg;charset=utf-8;base64,')
                            .replace(/<br>/g, '<br/>');
                      
                          if (!complianceTag) {
                            const parser = new DOMParser();
                            const doc = parser.parseFromString(processedHtml, 'text/html');
                            const complianceNodes = doc.querySelectorAll('span.compliance');
                            complianceNodes.forEach(node => node.remove());
                            processedHtml = doc.documentElement.innerHTML;
                          }
                          processedHtml = processedHtml.replace("<html>", "<html>" + revDetails);
                          setHtmlBase64(processedHtml);
                        })
                        .catch(error => console.error('Error fetching or processing HTML content:', error));
            })
          }
    }, [data])


    return loading ? (
      <div className={styles["spinner-section"]}>
        <div className={styles.spinner}></div>
      </div>
    ) : (
      <ShadowDOM>
       <div style={{ marginRight: "20px" }}>
      <div style={{
         color:"#000",
         fontSize:"32px",
        fontWeight:600,
        width:"100%"
      }}>{manualDescription.manualName}</div>
      <div style={{ fontSize: "16px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyItems: "space-between",
          alignItems: "center",
        }}
      >
         <div>
          <div>{manualDescription.fleetType}</div>
          <div>{`Rev # ${
            manualDescription.revNumber ? manualDescription.revNumber + " - " : ""
          } ${manualDescription.revNumber!==undefined ? moment(manualDescription.revDate).format(
            "MM/DD/YYYY"):""}`}</div>
        </div>
      </div>
      </div>
      </div>
  <div
    className={styles.titlebannericon}
    style={{
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      paddingTop: "12px",
      alignItems: "center",
      marginLeft: "auto",
      marginRight: "10.75px",
      backgroundColor:"#666"
    }}
  >
    <div
      className={styles.bannericon}
      style={{
        display: "flex",
        flexDirection: "row",
        marginRight: "19.81px",
        marginBottom: "9.5px",
        alignItems: "center",
        cursor:"pointer"
      }}
      onClick={() => handlePrintClick()}
    >
      <Icon variant="print" width="30px" height="34px" clickable={true} />
    </div>
  </div>
  <div
    className={styles.container}
    dangerouslySetInnerHTML={{ __html: htmlBase64 }}
  />
</ShadowDOM>
    );
}

export default HtmlViewer;
